import { FC } from 'react';
import ProfileInfo from '../ProfileInfo/ProfileInfo';

interface OperatorProps {}

const Topnav: FC<OperatorProps> = () => {
  return (
    <>
      <nav
        className="js-nav nav-02 nav-02--static"
        style={{ padding: 0, height: '70px' }}
      >
        <div className="container container--large">
          <div className="nav-02__box">
            <div className="nav-02__logo">
              <a
                className="nav-02__link"
                href="https://careerflow.ai/"
                target="_self"
                style={{ height: '70px' }}
              >
                <img
                  loading="lazy"
                  className="nav-02__logo_img"
                  src="static/img/Careerflow-logo.png"
                  height={47}
                  alt="FAANGPath"
                />
                <span className="nav-02__logo_text">
                  {process.env.REACT_APP_APP_NAME} | X-ray search
                </span>
              </a>
            </div>
            <div className="nav-02__links js-menu">
              <div className="nav-02__list_wrapper">
                <ul className="nav-02__list nav-02__list--desktop">
                  <li className="nav-02__item">
                    <ProfileInfo />
                  </li>
                  <li className="nav-02__item" />
                </ul>
                <ul className="nav-02__list nav-02__list--mobile">
                  <li className="nav-02__item">
                    <ProfileInfo />
                  </li>
                  <li className="nav-02__item" />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div className="top-description">
        <h1 className="heading text-white">
          Search for hiring managers and recruiters
        </h1>
        Are you looking for recruiters, hiring managers, team leads, etc. from
        your dream companies who are hiring but not sure how to find them?
        Let&nbsp;{process.env.REACT_APP_APP_NAME} | X-ray search - our recruiter
        search tool, be your job search best friend!
      </div>
    </>
  );
};

export default Topnav;
